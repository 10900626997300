<template>
  <AuthenticatedLayout v-if="isAuthenticated">
    <NewsPanel
      class="container"
      :class="device + '-container'"
      :has-menu-left="true"
    />
  </AuthenticatedLayout>
  <PublicLayout v-else>
    <NewsPanel />
  </PublicLayout>
</template>

<script>
import PublicLayout from '@/router/layouts/PublicLayout.vue'
import AuthenticatedLayout from '@/router/layouts/AuthenticatedLayout.vue'
import NewsPanel from '@components/News/NewsPanel'

export default {
  name: 'Events',
  components: {
    NewsPanel,
    PublicLayout,
    AuthenticatedLayout
  },
  inject: ['mq'],
  computed: {
    device () {
      if (this.mq.phone) {
        return 'phone'
      } else {
        return 'desktop'
      }
    },
    isAuthenticated () {
      return this.$store.getters['auth/isAuthenticated']
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@design";

.container {
  height: calc(100% - #{$desktop-search-bar-height});
  overflow-y: auto;
}

.phone-container {
  height: calc(100% - #{$mobile-menu-height});
}

</style>
