<template>
  <div
    class="container"
    :class="device + '-container'"
  >
    <h1 v-t="'Events.title'" />

    <div class="events">
      <splide
        v-if="events && events.length"
        :class="hasMenuLeft ? 'reduced-splide' : ''"
        :options="options"
        has-slider-wrapper
      >
        <splide-slide
          v-for="event in sortedEvents"
          :key="event.eventId"
          class="theme-background-color"
          :class="{ 'row': !(mq.phone || mq.tablet) }"
        >
          <img
            class="event-image"
            :src="event.thumbnail"
          >
          <div class="text">
            <h3>{{ event.title }}</h3>
            <p>{{ event.location }} {{ $t('Events.theLabel') }} {{ getFormattedDate(event.eventDate) }}</p>
            <p>{{ event.content }}</p>
            <div>
              <a
                v-if="event.linkUrl"
                :href="event.linkUrl"
                class="link"
                target="_blank"
              >{{ event.linkDescription }}</a>
            </div>
          </div>
        </splide-slide>

        <!-- <template #controls>
          <div class="splide__progress">
            <div class="splide__progress__bar" />
          </div>
        </template> -->
      </splide>
      <div v-else>
        <h3
          v-t="'Events.emptyEventList'"
          class="center"
        />
      </div>
    </div>

    <h2 v-t="'Events.newsTitle'" />
    <div class="news">
      <splide
        v-if="news && news.length"
        :options="newsOptions"
        :class="hasMenuLeft ? 'reduced-splide' : ''"
      >
        <splide-slide
          v-for="newsItem in news"
          :key="newsItem.newsId"
          class="news-slide theme-border-color theme-text-color"
        >
          <div class="new-img-container">
            <img
              class="news-image"
              :src="newsItem.thumbnail"
              alt=""
            >
          </div>

          <h3>{{ newsItem.title }}</h3>
          <p>{{ newsItem.content }}</p>
          <div class="center">
            <a
              v-if="newsItem.linkUrl"
              :href="newsItem.linkUrl"
              class="link"
              target="_blank"
            >{{ newsItem.linkDescription }}</a>
          </div>
        </splide-slide>
      </splide>
      <div v-else>
        <h3 v-t="'Events.emptyNewsList'" />
      </div>
    </div>
  </div>
</template>

<script>
import '@splidejs/splide/dist/css/themes/splide-default.min.css'
import { Splide, SplideSlide } from '@splidejs/vue-splide'
import dayjs from 'dayjs'
import eventService from '@/api/news/event.service'
import newsService from '@/api/news/news.service'
import moment from 'moment'

export default {
  name: 'NewsPanel',
  components: {
    Splide,
    SplideSlide
  },
  inject: ['mq'],
  props: {
    hasMenuLeft: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      events: undefined,
      news: undefined,
      options: {
        rewind: true,
        autoplay: true,
        pauseOnHover: true,
        arrows: !this.mq.phone,
        speed: 1000,
        interval: 7000,
        gap: '1rem',
        keyboard: 'focused'
      },
      newsOptions: {
        rewind: true,
        arrows: false,
        perPage: 0,
        gap: '1rem',
        keyboard: 'focused'
      }
    }
  },
  computed: {
    nbOptionsPerPage () {
      return this.mq.phone ? 1 : this.mq.tablet ? 2 : 3
    },
    sortedEvents () { // events, from younger to older
      return [...this.events].sort((a, b) => {
        return dayjs(b.eventDate, 'YYYY-MM-DD').diff(dayjs(a.eventDate, 'YYYY-MM-DD'))
      })
    },
    device () {
      if (this.mq.projectSizeLimit2 || this.mq.tablet) {
        return 'limit2'
      } else {
        return ''
      }
    }
  },
  mounted () {
    eventService.getPublicEventList(10)
      .then(data => {
        if (data.success) {
          this.events = data.eventList
        } else {
          throw new Error()
        }
      })
      .catch(err => {
        this.dispatch('popups/pushPopup', { message: 'ERROR' })
        console.error(err)
      })

    newsService.getPublicNewsList(50)
      .then(data => {
        if (data.success) {
          this.news = data.newsList
        } else {
          throw new Error()
        }
      })
      .catch(err => {
        this.dispatch('popups/pushPopup', { message: 'ERROR' })
        console.error(err)
      })
  },
  created () {
    this.newsOptions.perPage = this.nbOptionsPerPage
  },
  methods: {
    getFormattedDate (date) {
      return moment(date, 'YYYY-MM-DD').format('DD/MM/YY')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@design";

.container {
  background-color: $public-bg;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $color-light-text;

  h1 {
    text-align: center;
    margin-top: 3rem;
    font-size: 2rem;
    line-height: 2rem;
    margin-bottom: 36px;
  }

  h2 {
    text-align: center;
    font-size: 1.8rem;
    line-height: 1.8rem;
    margin-top: 3rem;
    margin-bottom: 2rem;
  }

  h3 {
    font-size: 1.3rem;
  }

  .center {
    text-align: center;
  }

  .reduced-splide {
    width: 1000px;
  }

}

.limit2-container {
  .reduced-splide {
    width: 500px;
  }
}

.events {
  width: 100%;

  .event-image {
    max-height: 326px;
    width: 100%;
    max-width: 488px;
    flex: 1 1 0;
  }

  .text {
    display: flex;
    flex-direction: column;
    flex: 1 1 0;

    div {
      margin-top: auto;
      height: 48px;
    }
  }

  .splide {
    max-width: 90%;
    margin: auto;

    &__autoplay {
      margin-top: 1em;
      text-align: center;
    }

    .splide__slide {
      padding: 24px;
      gap: 2rem;
      border-radius: 6px;

      &.row {
        display: flex;
      }
    }
  }
}

.news {
  max-width: 90%;
  margin: auto;
  margin-bottom: 3rem;

  .news-slide {
    background-color: white;
    border-bottom: 11px solid;
  }

  .new-img-container {
    display: flex;
    align-items: center;

    .news-image {
      max-width: 100%;
      max-height: 237px;
      width: auto;
      height: auto;
      margin: 0 auto;
    }
  }

  .center {
    text-align: center;
  }

  .link {
    margin: auto;
    display: inline-block;
  }

  .splide {
    .splide__slide {
      padding: 20px;
      gap: 2rem;
      border-radius: 6px;
      min-width: 250px;
    }
  }
}

.splide {
  .splide__pagination {
    position: relative;
    bottom: 0;
  }
}

.link {
  border: 1px solid $color-border-2;
  border-radius: 6px;
  background-color: white;
  color: black;
  padding: 10px 15px;
  text-decoration: none;
}
</style>

<style lang="scss">
.splide {
  p {
    word-wrap: break-word;
  }

  .splide__pagination {
    position: relative;
    bottom: 0;
    margin: 0.5rem 0;
  }

  .splide__pagination__page {
    height: 16px;
    width: 16px;
    background-color: white;
    opacity: 1;
    margin: 0 0.7rem;

    &.is-active {
      transform: none;
      background: #0B3C5F;
    }
  }
}
</style>
